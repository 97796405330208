import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { queryApi } from "@hooks/api";
import { Box, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CreditPackageResponse } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { performAction } from "@utils/actions";
import { addDays } from "date-fns";
import { useState } from "react";

export default function EditCreditPackageDialogComponent(props: {
  currentCreditPackage: CreditPackageResponse | null;
  isDialogOpen: boolean;
  onClose?: () => void;
}) {
  const [validCountries] = queryApi(async (options) =>
    await adminClient().adminUserAddressListOfValidCountriesGet(options), []);
  const [id] = useState(props.currentCreditPackage?.id ?? undefined);
  const [country, setCountry] = useState(props.currentCreditPackage?.country ?? "dk");
  const [priceInHundreds, setPriceInHundreds] = useState(props.currentCreditPackage?.priceInHundreds ?? 0);
  const [creditAmount, setCreditAmount] = useState(props.currentCreditPackage?.creditAmount ?? 0);
  const [ribbonText, setRibbonText] = useState(props.currentCreditPackage?.ribbonText ?? undefined);
  const [percent, setPercent] = useState(props.currentCreditPackage?.discount.percent ?? 0);

  function parseNumber(numberString: string | null | undefined) {
    const value = +(numberString || "");
    if (isNaN(value)) {
      return 0;
    }

    return value;
  }

  const onSubmit = async (fromDate: Date | null, toDate: Date | null) => {
    if (!fromDate || !toDate) {
      return;
    }
    await performAction(
      async () => await adminClient().adminCreditUpdateCreditPackagePut(
        {
          body: {
            id,
            country,
            priceInHundreds,
            creditAmount,
            discountPercent: percent,
            discountValidFromDate: fromDate,
            discountValidToDate: toDate,
            ribbonText: ribbonText
          }
        }),
      "Failed to change credit package");
  }

  return <Dialog
    open={props.isDialogOpen}
    onClose={() => {
      props.onClose && props.onClose()
    }}
  >
    <DialogTitle
      id="edit-dialog-slide-title">{"Credit Package"}
    </DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="country"
        label="Country"
        value={country.toUpperCase()}
        select
        fullWidth
        onChange={event => setCountry(event.target.value as Countries)}>
        {!validCountries
          ? <MenuItem key={"Loading"} value={"Loading"}>Loading</MenuItem>
          : validCountries.countries?.map((country) => (
            <MenuItem key={country} value={country.toUpperCase()}>
              {country}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        autoFocus
        margin="dense"
        id="priceInHundreds"
        label="Price in hundreds"
        defaultValue={priceInHundreds}
        fullWidth
        onChange={event => setPriceInHundreds(parseNumber(event.target.value))}
      />
      <TextField
        autoFocus
        margin="dense"
        id="creditAmount"
        label="Credit amount"
        defaultValue={creditAmount}
        fullWidth
        onChange={event => setCreditAmount(parseNumber(event.target.value))}
      />
      <TextField
        autoFocus
        margin="dense"
        id="ribbonText"
        label="Ribbon text"
        defaultValue={ribbonText}
        fullWidth
        onChange={event => setRibbonText(event.target.value)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="procent"
        label="Discount in procent"
        defaultValue={percent}
        fullWidth
        onChange={event => setPercent(parseNumber(event.target.value))}
      />
      <Box
        display="flex"
        flexDirection="reverse-row"
        alignItems="center"
        flexWrap="nowrap"
        style={{ marginTop: 16 }}>
        <DateRangePickerComponent
          onSubmit={onSubmit}
          renderTimeOption={true}
          renderDateRanges={false}
          disableFutureDates={false}
          disablePastDates={true}
          submitOnLoad={false}
          variant="standard"
          fromDateLabel="Valid from"
          toDateLabel="Valid to"
          defaultFromDate={props.currentCreditPackage?.discount.validFromDate ?? new Date()}
          defaultToDate={props.currentCreditPackage?.discount.validToDate ?? addDays(new Date(), 7)}
        />
      </Box>
    </DialogContent>
  </Dialog>
}