import EditCreditPackageDialogComponent from "@components/admin/credit-packages/EditCreditPackageDialogComponent";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CreditPackageResponse } from "@reshopper/admin-client";
import { useState } from "react";

export default function EditCreditPackageButtonComponent(props: {
  creditPackage: CreditPackageResponse | null,
  buttonTitle?: string,
  onClose?: () => void
}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose && props.onClose();
  }

  return <>
    <Button
      variant="contained"
      color="primary"
      startIcon={<EditIcon />}
      onClick={handleOpen}>
      {props.buttonTitle ?? "Edit Credit Package"}
    </Button>

    <EditCreditPackageDialogComponent
      currentCreditPackage={props.creditPackage}
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}