import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import EditCreditPackageButtonComponent from "@components/admin/credit-packages/EditCreditPackageButtonComponent";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import LineChartSectionComponent from "@components/admin/stats/LineChartSectionComponent";
import { GraphPoint, GraphStat } from "@components/admin/stats/StatsHelper";
import TableComponent, { Header } from "@components/TableComponent";
import { queryApi } from "@hooks/api";
import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { CreditPackageResponse, CreditRevenueStatsWithChangeListResponse } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { performAction } from "@utils/actions";
import { toDanishPriceFormat } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { endOfDay, startOfDay, subMonths } from "date-fns";
import { useEffect, useState } from "react";

const decreasePadding = { paddingLeft: "6px", paddingRight: "6px" };

export default withAdminMenuLayout(function RecentTransactionsPageComponent() {
	const now = new Date();

	const [fromDate, setFromDate] = useState<Date>(subMonths(startOfDay(now), 1));
	const [toDate, setToDate] = useState<Date>(endOfDay(now));

	const [stats, setStats] = useState<GraphStat[]>(new Array<GraphStat>());
	const [creditsRevenueStats, setCreditsRevenueStats] = useState<CreditRevenueStatsWithChangeListResponse | null>(null);
	const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
	const [openActivateDialog, setOpenActivateDialog] = useState(false);
	const [currentCreditPackage, setCurrentCreditPackage] = useState<CreditPackageResponse | null>(null);
	const [validCountries] = queryApi(async (options) =>
		await adminClient().adminUserAddressListOfValidCountriesGet(options), []);
	const [currentlySelectedCountry, setCurrentlySelectedCountry] = useState<Countries>("dk");

	useEffect(() => {
		setStatsFromDate(fromDate, toDate)
	},
		[])

	function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
		setCurrentlySelectedCountry(event.target.value as Countries);
	}

	function onSubmit(fromDate: Date | null, toDate: Date | null) {
		if (!fromDate || !toDate) {
			return;
		}
		setFromDate(fromDate);
		setToDate(toDate);
		setStatsFromDate(fromDate, toDate);
	}

	async function setStatsFromDate(fromDate: Date, toDate: Date) {
		let creditsRevenueStats = await adminClient().adminCreditGetCreditsRevenueStatsPost({
			body: {
				from: fromDate,
				to: toDate
			}
		});
		setCreditsRevenueStats(creditsRevenueStats);
		let graphStats = generateGraphStats(creditsRevenueStats);
		setStats(graphStats);
	}

	function generateGraphStats(creditsRevenueStats: CreditRevenueStatsWithChangeListResponse) {
		let totalPriceInHundredsGraphPoints = new Array<GraphPoint>();
		let result = new Array<GraphStat>();

		creditsRevenueStats?.creditRevenueStatsWithChangeList.forEach(list => {
			list.creditRevenueStatsList.forEach(stat =>
				totalPriceInHundredsGraphPoints.push(
					addDataPoint(stat.timeUtc, stat.totalPriceInHundreds / 100)
				),
			)
			result.push({ title: list.change.toString(), data: totalPriceInHundredsGraphPoints } as GraphStat)
			totalPriceInHundredsGraphPoints = new Array<GraphPoint>()
		}
		);
		return result;
	}

	function addDataPoint(timeUtc: Date, totalPriceInHundreds: number) {
		return { x: timeUtc, y1: totalPriceInHundreds } as GraphPoint
	}

	function createRevenueTable() {
		return <>
			<Paper>
				<Table size="small">
					<TableHead>
						<TableRow key="revenueHeader">
							<TableCell>Package</TableCell>
							<TableCell>Revenue (incl. VAT)</TableCell>
							<TableCell>Count</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{creditsRevenueStats?.creditRevenueStatsWithChangeList.map(creditPackage =>
							<TableRow key="revenueBody">
								<TableCell>
									{creditPackage.change}
								</TableCell>
								<TableCell>
									{toDanishPriceFormat(creditPackage.revenue / 100)}
								</TableCell>
								<TableCell>
									{creditPackage.count}
								</TableCell>
							</TableRow>
						)
						}
						<TableRow>
							<TableCell>

							</TableCell>
							<TableCell>
								<Box sx={{ fontWeight: 'bold' }}> Combined Revnue (incl. VAT)</Box>
							</TableCell>
							<TableCell>
								<Box sx={{ fontWeight: 'bold' }}> Combined Count</Box>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
							</TableCell>
							<TableCell>
								{toDanishPriceFormat(calculateTotalCreditsRevenue() / 100)}
							</TableCell>
							<TableCell>
								{calculateCreditsRevenueTotalCount()}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		</>;
	}

	function calculateCreditsRevenueTotalCount() {
		if (!creditsRevenueStats?.creditRevenueStatsWithChangeList) {
			return 0;
		}

		return creditsRevenueStats.creditRevenueStatsWithChangeList.map(list => list).reduce((sum, current) => sum + current.count, 0);
	}

	function calculateTotalCreditsRevenue() {
		if (!creditsRevenueStats?.creditRevenueStatsWithChangeList) {
			return 0;
		}

		return creditsRevenueStats.creditRevenueStatsWithChangeList.map(list => list).reduce((sum, current) => sum + current.revenue, 0);
	}

	function onDeactivation() {
		if (!currentCreditPackage)
			throw new Error("No package specified.");

		performAction(
			() => adminClient().adminCreditDeactivateCreditPackagePut(currentCreditPackage.id),
			"Failed to delete credit package")
	}

	function onActivation() {
		if (!currentCreditPackage)
			throw new Error("No package specified.");

		performAction(
			() => adminClient().adminCreditActivateCreditPackagePut(currentCreditPackage.id),
			"Failed to activate credit package")
	}

	const creditPackageHeaders: Header<CreditPackageResponse>[] = [
		{
			databaseSortingKey: x => x.priceInHundreds,
			label: "Price (in hundreds)"
		},
		{
			databaseSortingKey: x => x.creditAmount,
			label: "Amount of Credits",
			isDefault: true
		},
		{
			databaseSortingKey: x => x.ribbonText,
			label: "Ribbon text"
		},
		{
			databaseSortingKey: x => x.discount.percent,
			label: "Discount in %"
		},
		{
			databaseSortingKey: x => x.discount.validFromDate,
			label: "Discount valid from date"
		},
		{
			databaseSortingKey: x => x.discount.validToDate,
			label: "Discount valid to date"
		},

		{
			databaseSortingKey: null,
			label: ""
		}
	];

	function renderCreditPackageRow(creditPackage: CreditPackageResponse) {
		return <>
			<TableCell style={decreasePadding}>
				{creditPackage.priceInHundreds}
			</TableCell>
			<TableCell style={decreasePadding}>
				{creditPackage.creditAmount}
			</TableCell>
			<TableCell style={decreasePadding}>
				{creditPackage.ribbonText}
			</TableCell>
			<TableCell style={decreasePadding}>
				{creditPackage.discount.percent}
			</TableCell>
			<TableCell style={decreasePadding}>
				{toDanishDateFormat(creditPackage.discount.validFromDate)}
			</TableCell>
			<TableCell style={decreasePadding}>
				{toDanishDateFormat(creditPackage.discount.validToDate)}
			</TableCell>
			{creditPackage.deactivatedAtUtc == null ?
				<TableCell style={decreasePadding}>
					<Box style={{ display: "flex", gap: "10px" }}>
						<EditCreditPackageButtonComponent
							creditPackage={creditPackage}
						/>
						<Button
							variant="contained"
							startIcon={<PauseIcon />}
							style={{backgroundColor: "#ed6c02", color: "#fff"}}
							onClick={() => {
								setCurrentCreditPackage(creditPackage)
								setOpenDeactivateDialog(true)
							}}
						>
							Deactivate
						</Button>
					</Box>
				</TableCell>
				:
				<>
					<TableCell style={decreasePadding}>
						<Box style={{ display: "flex", gap: "10px" }}>
							<EditCreditPackageButtonComponent
								creditPackage={creditPackage}
							/>
							<Button
								variant="contained"
								startIcon={<PlayArrowIcon />}
								style={{backgroundColor: "#5EB133", color: "#fff"}}
								onClick={() => {
									setCurrentCreditPackage(creditPackage)
									setOpenActivateDialog(true)
								}}
							>
								Activate
							</Button>
						</Box>
					</TableCell>
				</>
			}
		</>
	}

	return <>
		<PageTitle title="" />
		<Card>
			<CardContent>
				<Typography variant="h4">Revenue</Typography>
				<Box
					display="flex"
					flexDirection="reverse-row"
					alignItems="center"
					flexWrap="nowrap"
					justifyContent="flex-end"
					paddingBottom={2}
				>
					<DateRangePickerComponent
						onSubmit={onSubmit}
						renderTimeOption={false}
						renderDateRanges={true}
						disableFutureDates={true}
						disablePastDates={false}
						submitOnLoad={false}
						variant="outlined"
						defaultFromDate={fromDate}
						defaultToDate={toDate}
					/>
				</Box>
				{stats.length !== 0 ?
					<>
						<LineChartSectionComponent
							stats={stats}
							key="totalCreditsRevenue"
							title=""
							from={fromDate}
							to={toDate}
							labelSuffix=" DKK"
							displayZValue={false} />
						<Box
							display="flex"
							flexDirection="reverse-row"
							alignItems="center"
							flexWrap="nowrap"
							justifyContent="flex-end"
						>
						</Box>
					</> : <></>}
				<>
					{creditsRevenueStats?.creditRevenueStatsWithChangeList && creditsRevenueStats?.creditRevenueStatsWithChangeList.length > 0 &&
						createRevenueTable()
					}
				</>
			</CardContent>
		</Card>

		<Card style={{ marginTop: 32 }}>
			<CardContent>
				<Typography variant="h4">Credits</Typography>
				<Box
					display="flex"
					flexDirection="reverse-row"
					alignItems="center"
					flexWrap="nowrap"
					justifyContent="flex-end"
					paddingBottom={2}
				>
					<EditCreditPackageButtonComponent
						buttonTitle="Create credit package"
						creditPackage={null}
					/>
				</Box>


				<Box
					display="flex"
					flexDirection="reverse-row"
					alignItems="center"
					flexWrap="nowrap"
					justifyContent="flex-end"
					paddingBottom={2}
				>

					{validCountries &&
						<Box>
							<FormControl
								fullWidth={true}
								style={{
									minWidth: 40,
									marginRight: 16
								}}>
								<InputLabel>
									Countries
								</InputLabel>
								<Select
									native={false}
									value={currentlySelectedCountry}
									onChange={handleChange}
								>
									{validCountries.countries.map(country => {
										return <MenuItem value={country}>{country.toUpperCase()}</MenuItem>
									})}
								</Select>
							</FormControl>
						</Box>}
				</Box>
				<TableComponent<CreditPackageResponse>
					url={"/admin/credits/packages/" + currentlySelectedCountry}
					isSelectable={false}
					headers={creditPackageHeaders}
					renderRow={device => renderCreditPackageRow(device)}
					sortDirection="asc"
				/>
			</CardContent>
		</Card>

		<ConfirmationDialogComponent
			isDialogOpen={openDeactivateDialog}
			onClose={() => setOpenDeactivateDialog(false)}
			onConfirm={onDeactivation}
			title="Confirm Deactivation"
			description={`Are you sure you want to deactivate the credit package ${currentCreditPackage?.id}?`}
		/>
		<ConfirmationDialogComponent
			isDialogOpen={openActivateDialog}
			onClose={() => setOpenActivateDialog(false)}
			onConfirm={onActivation}
			title="Confirm Activation"
			description={`Are you sure you want to Activate the credit package ${currentCreditPackage?.id}?`}
		/>
	</>
});